/* margin */
@mixin margin($value) {
  margin-left: $value;
  margin-right: $value;
  margin-top: $value;
  margin-bottom: $value;
}
$marginproperty: (margin);
@mixin margin-value($side, $value) {
    @each $prop in $marginproperty {
        #{$prop}-#{$side}: $value;
    }
}
/* padding */
@mixin padding($value) {
  padding-left: $value;
  padding-right: $value;
  padding-top: $value;
  padding-bottom: $value;
}

$paddingproperty: (padding);
@mixin padding-value($side, $value) {
    @each $prop in $paddingproperty {
        #{$prop}-#{$side}: $value;
    }
}

  @mixin line-breaker{
    display: block;
    height: 3px;
    background-color: $picton-blue;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    width: 80%;
  }

  @mixin website-section-title{
    font-size: 24px;
    font-weight: 600;
    font-family: $raleway;
    color: $black;
  }

@mixin app-page-title {
  font-family: $roboto;
  color: #212121;
  font-size: 24px;
  line-height: 36px;
}
@mixin app-card-title {
  font-family: $roboto;
  color: #212121;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;  
}
@mixin app-card-medium-title {
  font-family: $roboto;
  color: #212121;
  font-size: 18px;  
  font-weight: 500;
  line-height: 21px;
}
@mixin app-card-small-title {
  font-family: $roboto;
  color: #212121;
  font-weight: 500;
  font-size: 16px;	
  line-height: 19px;
}

@mixin app-card-medium-text {
  font-family: $roboto;
  color: #7A7A7A;  
  font-size: 16px;	
  line-height: 19px;
}
@mixin  app-card-text {
  color: #7A7A7A;	
  font-family: $opensans;	
  font-size: 14px;	
  line-height: 21px;
}
@mixin app-card-subtitle {
  color: #212121;	
  font-family:$opensans;	
  font-size: 14px;	
  font-weight: 600;	
  line-height: 24px;
}
@mixin app-card-text-opensans-small($color) {
  color:$color ;	
  font-family:$opensans;	
  font-size: 14px;	
  font-weight: 600;	
  line-height: 24px;
}
@mixin app-card-text-opensans-medium($color) {
  color: $color;	
  font-family:$opensans;	
  font-size: 16px;	
  line-height: 22px;
}
@mixin app-card-subtext {
  color: #7A7A7A;	
  font-family: $opensans;	
  font-size: 12px;	
  line-height: 24px;
}
@mixin app-chips-text-medium($font-color){
  color: $font-color;	
  font-family: $roboto;	
  font-size: 14px;	
  font-weight: 600;	
  line-height: 21px;
}
@mixin app-chips-text-small($font-color){
  color: $font-color;	
  font-family: $opensans;	
  font-size: 12px;	
  line-height: 20px;
}

@mixin flex_column {
  display: flex;
  flex-direction: column;
}

@mixin flex_jc_center {
  display: flex;
  justify-content: center;
}

@mixin flex_center {
  @include flex_jc_center;
  align-items: center;
}
