/* You can add global styles to this file, and also import other style files */

/* styles.css will be extracted to main dist folder and is imported in index.html */
/* This file is for importing 3rd party styles  */
@import 'app/sass/index';
// Plus imports for other components in your app.
// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
//@import '~@angular/material/theming';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
//$kp-material-primary: mat-palette($mat-light-blue, 600);
//$kp-material-accent:  mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
//$kp-material-warn:    mat-palette($mat-red);
//@include mat-core();
// Create the theme object (a Sass map containing all of the palettes).
//$kp-material-theme: mat-light-theme($kp-material-primary, $kp-material-accent, $kp-material-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
/*@include angular-material-theme($kp-material-theme);
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}*/
html, body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: $opensans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background-color: $raisin_black; 
  overflow: hidden;
}
pre {
  font-family: $opensans;
  font-size: 14px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
/**
  * Basic styles from Material Design Lite for HTML elements
  */
  h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0; }
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-family: $oswald;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54;
  font-size: 0.6em; }
h1 {
  font-family: $oswald;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  margin-top: 24px;
  margin-bottom: 24px; }
h2 {
  font-family: $oswald;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  margin-top: 24px;
  margin-bottom: 24px; }
h3 {
  font-family: $oswald;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  margin-top: 24px;
  margin-bottom: 24px; }
h4 {
  font-family: $oswald;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 24px;
  margin-bottom: 16px; }
h5 {
  font-family: $oswald;
  font-size: 8px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  margin-top: 24px;
  margin-bottom: 16px; }
h6 {
  font-family: $oswald;
  font-size: 4px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  margin-top: 24px;
  margin-bottom: 16px; }
p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0;
  margin-bottom: 0; }
a {
  font-weight: 500; }
blockquote {
  font-family: $opensans;
  position: relative;
  font-size: 24px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.35;
  letter-spacing: 0.08em; }
  blockquote:before {
    position: absolute;
    left: -0.5em;
    content: '“'; }
  blockquote:after {
    content: '”';
    margin-left: -0.05em; }
mark {
  background-color: #f4ff81; }
dt {
  font-weight: 700; }
address {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  font-style: normal; }
ul, ol {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0; 
}
/*mat-progress-bar .mat-progress-bar-fill::after {
  background: linear-gradient(90deg, #72AFD3 0%, #2FDBAB 100%);
}
mat-progress-bar .mat-progress-bar-buffer{
   background-color: #ECECEC;
   border-radius: 8px;

}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #054874;
}

.mat-tab-label{
  color: #9E9E9E;	
  font-family: "Open Sans";	
  font-size: 16px;	
  font-weight: 600;	
  line-height: 27px;
}
.mat-tab-label.mat-tab-label-active{
    color: #054874
}
.mat-radio-label{
  font-weight: 400 !important;
}*/
