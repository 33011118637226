/* font variables */
$oswald: "Oswald", sans-serif;
$opensans: "Open Sans", sans-serif;
$raleway: "Raleway";
$roboto: "Roboto", sans-serif;

/* number variables */
$step-dimension: 50px;

$break-small: 320px;
$break-mobile: 800px;
$break-large: 1200px;

/* colors */
$shamrock-color: #3ed27e;
$lyncy-color: #637d9a;
$picton-blue-color: #2ab1ea;
$shark-color: #22252c;
$bright-grey-color: #3c4555;
$san-juan-color: #36537b;
$havelock-blue-color: #488fe2;

%tfl__cp {
  cursor: pointer;
}
