// talfinder colors
$fun-blue: #1873ad;
$gallery: #eeeeee;
$malibu: #66ccff;
$light-blue: #56cdff;
$link-water: #c9dff5;
$venice-blue: #064973;
$vivid-blue: #03a9f4;
$red: #ff0000;
$monza: #d0021b;
$white: #ffffff;
$dark-grey: #888888;
$dark-grey-8f: #8f8f8f;
$silver-chalice: #9e9e9e;
$forest-green: #29791c;
$limed-spruce: #3c4b52;
$iron: #d5dddd;
$porcelain: #ecf0f1;
$java: #20c7a5;
$regent-gray: #969fa3;
$curious-blue: #1f93c6;
$sunglow: #ffca28;
$loblolly: #bcc7cc;
$picton-blue: #2ab1e9;
$concrete: #f2f2f2;
$geyser: #cedae0;
$alto: #dddddd;
$nevada: #5a666b;
$denim: #106cc8;
$dove-gray: #f4f4f4;
$oslo-gray: #849194;
$dusty-gray: #999999;
$nobel: #b3b3b3;
$alabaster: #fafafa;
$tower-gray: #a8b6bd;
$gull-gray: #99aab2;
$cutty-sark: #4f636b;
$chestnut-rose: #d16572;
$dark-blue: #064068;
$black: #000000;
$emperor: #555555;
$slate-gray: #73848c;
$pickled-bluewood: #34495e;
$heather: #b1c4cd;
$outer-space: #333a3d;
$haiti: #0b132b;
$boulder: #767676;
$picton-blue: #2ab1ea;
$sky-blue: #6ec4ea;
$bali-hai: #7e9eb2;
$biscay: #1a2b56;
$emerald: #4ed242;
$nevada: #68707b;
$mischka: #dfe1e6;
$violet: #140a2f;
$mint-tulip-color: #c4e4f3;
$Bianca: #fefefa;
$storm-gray: #7d7d7e;
$jade: #00c16f;
$balticsea: #272628;
$catskillwhite: #f3f5f9;
$gray29: #4a4a4a;
$deepskyblue: #00b0ec;
$darkcerulean: #004878;
$bunting: #152347;
$Bianca: #fefefa;
$regal-blue-color: #054874;
$celurean-color: #00aee6;
$orient: #016282;
$regent-gray-2-color: #7f8a96;
$baltic-sea: #272628;
$matisse-color: #2261ad;
$catskill-white-color: #f3f5f9;
$cornflower-blue-color: #4285f4;
$jumbo-color: #7d7d7e;
$river-bed-color: #455360;
$santas-gray: #9fa0a3;
$shamrock: #3ad29f;
$dusty-gray: #9b9b9b;
$che-theia-menu: #3c3c3c;
$kodepro-blue: #039be5;
$dodger-blue: #2196f3;
$black-light: #212121;
$grey-7a: #7a7a7a;
$grey-65: #656565;
$raisin_black: #242426;
$emerald: #3cd27d;
