/*

this is the custom bootstrap classes which are available in bootstrap v-4.0.
since we are using bootstrap v-3.3 in our application these features are not
available and in future if we use bootstrap v-4.0 we can remove this file.

*/

$screen-xs-min  : 576px;
$screen-sm-min  : 768px;
$screen-md-min  : 1024px;
$sreen-lg-min   : 1200px;

.text-xs-left { text-align: left; }
.text-xs-right { text-align: right; }
.text-xs-center { text-align: center; }
.text-xs-justify { text-align: justify; }

@media (min-width: $screen-sm-min) {
    .text-sm-left { text-align: left; }
    .text-sm-right { text-align: right; }
    .text-sm-center { text-align: center; }
    .text-sm-justify { text-align: justify; }
}

@media (min-width: $screen-md-min) {
    .text-md-left { text-align: left; }
    .text-md-right { text-align: right; }
    .text-md-center { text-align: center; }
    .text-md-justify { text-align: justify; }
}

@media (min-width: $sreen-lg-min) {
    .text-lg-left { text-align: left; }
    .text-lg-right { text-align: right; }
    .text-lg-center { text-align: center; }
    .text-lg-justify { text-align: justify; }
}
